@import '../../../../../scss/_trashie.scss';

.MyRewardItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width: 100%;
    border-radius: 20px;
    background-color: var(--trashie-ui-crisp-white);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

    &__TcCredit {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__contentLinks {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__icon {
        height: 18px;
        width: 18px;
    }

    &__iconLink {
        height: 16px;
        width: 16px;
    }

    &__label {
        margin: 0px;
    }

    &__expired {
        padding-left: 5px;
    }

    &__card {
        display: flex;
        width: 190px;
        height: 120px;
        align-self: flex-start;
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        width: 100%;

        &--title {
            @include ctaLink;

            font-size: 20px;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            color: var(--ui-faded-black);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
        }

        &--expiration {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 2px 6px;
            gap: 6px;
            border-radius: 10px;
            border: 0.5px solid var(--trashie-ui-faded-black-30);
            background: var(--trashie-ui-faded-black-20);

            img {
                height: 13px;
            }

            span {
                font-size: 12px;
                font-weight: 400;
                line-height: 150%;
                color: var(--ui-faded-black);
                text-transform: none;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }
        }

        &--details {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            max-width: 100%;

            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            color: var(--trashie-ui-faded-black-60);
            text-transform: none;
            white-space: nowrap;


            .details-label {
                font-weight: bold;

                &:not(:first-of-type) {
                    margin-left: 10px;
                }
            }

            .details-small {
                font-size: 12px;
                white-space: wrap;
            }

            .details-highlight {
                background-color: var(--trashie-ui-sky-boi);
                max-width: 80%;
            }

            &.details-highlight {
                border-radius: 8px;
                background-color: var(--trashie-ui-sky-boi);
                padding: 4px 8px;
            }

            .contentDetails--red {
                color: var(--trashie-primary-rich-red);
            }
        }



        &--link {
            @include ctaLink;
            @include ctaSmall;
            font-size: 12px;
            margin-top: 8px;
            display: flex;
            align-items: center;
            gap: 4px;
            min-width: 150px;
        }

        &.expired {
            .MyRewardItem__content--expiration {
                background: var(--trashie-ui-error-background);
            }
        }
    }

    &__cta {
        @include ctaQuinary;
        @include ctaLarge;
        width: 240px;
        border-radius: 9999px;
    }
}

@media screen and (max-width: 1020px) {
    .MyRewardItem {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        &__card {
            width: 96px;
            height: 60px;
        }

        &__expired {
            padding-left: 13px;
        }

        &__content {
            align-self: center;
            align-items: flex-start;
            gap: 8px;
            flex: auto;

            &--details {
                align-items: flex-start;
                gap: 4px;
            }

            &--link {
                margin: 0;
            }

            &.content-mobile {
                max-width: 60%;

                span {
                    white-space: wrap;
                }
            }

            &:last-of-type {
                margin: 6px 0;
            }
        }

        &__cta {
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .MyRewardItem {
        &__contentLinks {
            justify-content: space-between;
        }

        &__content {
            &--link {
                max-width: fit-content;
                justify-content: center;
            }
        }

    }
}