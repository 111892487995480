@import '../../../../scss/trashie.scss';

.ItemListContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: "GT Maru";

    &__title {
        color: var(--trashie-ui-faded-black-100);
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        text-transform: none;
    }

    &__item {
        padding: 0px;
    }

    &__item-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 120%;
    }

    &__item-lable {
        color: var(--trashie-ui-faded-black-50);
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
        text-align: left;
    }

    &__item-content {
        border-radius: 8px;
        background-color: var(--ui-crisp-white);

        /* Drop Shadow */
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
        padding: 16px;
        width: 100%;
        display: flex;
        gap: 16px;
    }

    &__item-text {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__item-icon {
        flex: 0 0 20px;
        width: 100%;
        max-width: 20px;
    }


    &__password {
        padding: 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 120%;
    }
}

@media screen and (max-width: 768px) {
    .ItemListContainer {
        &__title {
            font-size: 16px;
        }

        &__item-text {
            gap: 4px
        }
    }
}