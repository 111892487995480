.Dashboard {
    display: flex;
    width: 100%;
    padding: 0 48px;
    flex-direction: column;
    overflow: hidden;

    &__container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;
    }
}

@media screen and (max-width: 1020px) {
    .Dashboard {
        padding: 0;
        background: none;
    }
}
