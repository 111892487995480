@import "../../../../../scss/trashie";

.RewardsViewItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 214px;
  padding: 0;
  border-radius: 8px;
  background: none;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  position: relative;
  width: 100%;
  gap: 8px;

  &:hover {
    transform: scale(1.025);
  }

  &__gem {
    position: absolute;
    top: 6px;
    left: 6px;
    height: 24px;
    width: 24px;
    z-index: 1;
  }

  &__lockedIcon{
    background: var(--trashie-ui-faded-black-30);
    position: absolute;
    top: 6px;
    left: 6px;
    height: 24px;
    width: 24px;
    z-index: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &--icon{
      height: 24px;
      width: 24px;
    }
  }

  &__caret {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    border: 0;
    padding: 0;
    border-radius: 8px;
    background: none;

    &--cover {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 160px;
      flex-shrink: 0;
      background-position: center;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding: 12px;
      overflow: hidden;
      border-radius: 8px;

      &-logo {
        max-width: 70%;
        filter: brightness(0) invert(1);
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    &--content {
      display: flex;
      flex: 1;
      flex-direction: row;
      gap: 4px;
      width: 100%;

      &-text {
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 100%;

        &-title {
          color: var(--trashie-ui-faded-black-100);
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          text-transform: uppercase;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }

        &-subtitle {
          color: var(--trashie-ui-faded-black-80);
          font-size: 11px;
          font-weight: 500;
          line-height: 120%;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0;
        }
      }

      &-arrow {
        padding: 8px;

        svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__cta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    width: 100%;
    border-radius: 100px;
    border: 1px solid var(--trashie-primary-code-blue);
    background: var(--trashie-ui-crisp-white);

    &--label {
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      color: var(--trashie-primary-code-blue);
      text-transform: capitalize;
      &--locked {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 2PX;
      }
    }
  }

  &.withCta {
    height: 255px;
  }

  &.disabled {
    .RewardsViewItem__caret--cover,
    .RewardsViewItem__content {
      opacity: 0.6;

      .RewardItem__content--amount {
        background: linear-gradient(to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%);
      }
    }
  }
}
