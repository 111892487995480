@import '../../../../scss/trashie.scss';

.ImpactMetrics {
    display: flex;
    align-self: stretch;
    width: 100%;
    justify-content: center;
    text-transform: none;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 1 auto;
    }

    &__icon {
        width: 100%;
    }

    &__icon-container {
        position: absolute;
        right: -16px;
        bottom: -32px;
        width: 135px;

        &--bigger {
            right: -32px;
            width: 180px;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
    }

    &__load-container {
        display: flex;
        min-height: 232px;
        width: 100%;
        align-items: center;
    }

    &__content {
        display: grid;
        grid-template-areas:
            'land air'
            'land water';
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: max(116px, 100%);
        gap: 8px;
    }

    &__content-item {
        display: flex;
        padding: 16px 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 8px;
        box-shadow: 0px 3.846px 14.423px 0px rgba(0, 0, 0, 0.09);
        position: relative;
        overflow: hidden;

        &--land {
            grid-area: land;
            background: var(--trashie-primary-berlin-yellow);
            padding: 32px;
        }

        &--air {
            grid-area: air;
            background: var(--trashie-primary-code-blue);
        }

        &--water {
            grid-area: water;
            background: var(--take-back-bags-icee);
        }
    }


    &__metric-number {
        font-size: 24px;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;

        &--bigger {
            font-size: 32px;
        }
    }

    &__metric-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        max-width: 210px;
        color: var(--trashie-ui-faded-black-70);
    }

    &__metric-equivalence {
        font-size: 11px;
        font-weight: 400;
        line-height: 150%;
        color: var(--trashie-ui-faded-black-70);


    }

    &__metrics {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4px;
        align-self: stretch;
        height: 80px;

        &--contrast {
            color: var(--ui-crisp-white);
        }
    }

}

@media screen and (max-width: 768px) {
    .ImpactMetrics {
        &__title {
            font-size: 16px;
        }

        &__content-item {
            padding: 16px;
        }

        &__metric-number {
            font-size: 18px;
            max-width: max(103px, 60%);

            &--bigger {
                font-size: 20px;
                max-width: 100%;
            }
        }

        &__icon-container {
            right: -7px;
            top: -10px;
            width: 60px;

            &--bigger {
                right: -20px;
                bottom: -20px;
                top: unset;
                width: 120px;
            }
        }

        &__metric-equivalence {
            font-size: 10px;
        }

        &__content {
            height: max(126px, 100%);
        }

        &__metrics {
            height: 90px;

            &--bigger {
                height: 100%;
                justify-content: flex-start;
            }
        }


        &__load-container {
            min-height: 252px;
        }

    }
}