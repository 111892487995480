.ShippingAndReward {
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        &--shipping {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            &-img {
                align-self: center;
                height: 40px;
            }
        }

        &--coupon {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--description {
                color: var(--trashie-ui-faded-black-80);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
        }

        &--rewardImage {
            width: 100%;
        }

        &--reward {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            &--description {
                color: var(--trashie-ui-faded-black-80);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }

        }

    }

    &__dialog {
        display: flex;
        padding: 32px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
        max-width: 343px;
        width: 100%;

        &--content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--title {
                color: var(--trashie-ui-faded-black-100);
                text-align: center;
                font-family: "GT Maru";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                text-transform: uppercase;
            }

            &--mesages {
                &--message {
                    display: flex;
                    padding: 8px;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;
                    color: var(--trashie-ui-faded-black-100);
                    font-family: "GT Maru";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    img{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}
