.DealsView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 350px;
  overflow-x: clip;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 1344px; // width + padding
    padding: 16px 0 0;

    &--header {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-content: flex-start;
      padding: 16px 0;

      &-title {
        color: var(--trashie-ui-faded-black-100);
        font-size: 28px;
        font-weight: 700;
        line-height: 140%;
        text-transform: none;
        margin: 0;
      }

      &-subtitle {
        color: var(--trashie-ui-faded-black-80);
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        margin: 0;
      }
    }

    &--loader {
      margin: 120px auto;
      width: 100%;
    }

    &--button {
      width: fit-content;
    }

    &--section {
      margin-top: 16px;
      background: var(--ui-crisp-white);
      border-radius: 20px;
      overflow: hidden;
    }

    &--controls {
      display: flex;
      flex-direction: column;
      margin: auto;
      justify-content: center;
      position: sticky;
      top: 90px;
      z-index: 9;
      background-color: var(--trashie-ui-faded-black-10);
      width: 100%;
      padding: 0 16px;
      scroll-margin-top: 90px;
      align-items: center;

      &.hasEnvBanner {
        top: 108px;
        scroll-margin-top: 108px;
      }

      &-tabs {
        width: 100%;
      }

      &-filter-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: var(--trashie-ui-faded-black-10);
        border-radius: 8px;
      }

      &-options {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        padding: 4px 0;
        .SearchBar {
          flex: 2;
        }

        .Filter,
        .Sorting {
          flex: 1;
        }
      }
    }

    &--pane {
      width: 100%;
      min-height: 400px;
      margin: 32px 0;
      scroll-margin-top: 194px;

      &.hasEnvBanner {
        scroll-margin-top: 210px;
      }
    }

    &--page {
      padding: 0px !important;
      .PageContent__container {
        padding: 0px;
      }
    }

    &--scroll {
      // Hide scrollbars
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__ctaContainer {
    display: none;
  }
}

@media screen and (max-width: 1020px) {
  .DealsView {
    &__content {
      margin: 0 16px 32px;
      padding: 0;

      &--header {
        padding: 8px 16px;
        margin: 0;
        background-color: var(--trashie-ui-crisp-white);

        &-title {
          display: none;
        }

        &-subtitle {
          font-size: 14px;
        }
      }

      &--controls {
        flex-direction: column;
        top: 52px;
        align-items: stretch;
        background-color: var(--trashie-ui-crisp-white);

        &.hasEnvBanner {
          top: 66px;
        }

        &-options {
          width: 100%;

          > div {
            width: 50%;
          }
        }
      }

      &--sorting {
        width: 100%;
        padding: 16px;
        position: sticky;
        top: 143px;
        z-index: 9;
        height: 100%;
        background: var(--trashie-ui-faded-black-10);

        &.hasEnvBanner {
          top: 158px;
        }
      }

      &--pane {
        margin: 16px 0;
        scroll-margin-top: 206px;

        &.hasEnvBanner {
          scroll-margin-top: 224px;
        }
      }

      &--page {
        .PageContent__content {
          align-items: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .DealsView {
    &__content {
      &--slider {
        max-height: 420px;
        margin: 0;
      }

      &--header {
        &-subtitle {
          display: none;
        }
      }
    }
  }
}
