.GiftCardContentMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    min-height: 432px;

    &__content {
        display: flex;
        max-width: 646px;
        padding: 24px 32px 32px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        flex: 1 0 0;

        &-action {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            &-button {
                width: auto;
                align-self: flex-start;
            }

            &-message {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;
                margin-bottom: 16px;

                &-title {
                    color: var(--trashie-ui-faded-black-100);
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 150%;
                    text-transform: uppercase;
                    margin: 0;

                    a {
                        text-decoration: underline;
                    }
                }

                &-desc {
                    color: var(--trashie-ui-faded-black-60);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    text-transform: none;
                    margin: 0;

                    img {
                        height: 16px;
                    }
                }
            }
        }
    }
}

.GiftCardContentSummary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 16px;

    &__title {
        color: var(--trashie-ui-faded-black-100);
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        text-align: left;
        margin: 0;
    }

    &__message {
        color: var(--trashie-ui-faded-black-60);
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        text-align: left;
        margin: 0;
        text-transform: none;
    }

    .message {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;

        .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
    
            img {
            max-height: 27px;
            max-width: 27px;
            }
        }

        .label-container {
            margin-bottom: 0;
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: none;
        }
    }
}

.GiftCardContentTerms {
    display: flex;
    padding: 16px 16px 32px 16px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    margin-top: 48px;
    width: 100%;
    min-height: 432px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        &-title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
        }

        &-markdown {
            color: var(--trashie-ui-faded-black-60);
            font-family: "GT Maru";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 0;
            padding: 0;
        }
    }

    &__bottom {
        width: 100%;
        display: flex;
        justify-content: right;

        &-button {
            max-width: 116px;
        }
    }
}

.GiftCardContentConfirm,
.GiftCardContentSuccess {
    display: flex;
    padding: 80px 32px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    min-height: 432px;
    background-color: var(--trashie-ui-soft-mint);

    &__icon {
      text-align: center;
    }

    &__title {
        color: var(--trashie-ui-faded-black-100);
        text-align: left;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        align-self: flex-start;
        margin-top: 16px;
    }

    &__desc {
        color: var(--trashie-ui-faded-black-100);
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        text-align: center;
        text-transform: uppercase;
    }

    &__actions {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 8px;
        padding: 48px 32px;
        align-items: flex-start;

        &-action {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;

            img {
                height: 20px;
            }

            &-name {
                color: var(--trashie-ui-faded-black-100);
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }

    &__terms {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-bottom: 30px;
        padding: 0;
    
        > input {
            border-radius: 5px;
            border: 2px solid var(--trashie-ui-faded-black-100-b) !important;
            width: 18px;
            height: 18px;
            margin: 2px 0 0;
            cursor: pointer;
            margin: 0 !important;

            &:checked {
                background-color: var(--japan-color-hard-mint);
            }
        }
    
        label {
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }
    
        a {
            color: #33F;
        }
      }
}

.GiftCardContentConfirm {
    background-color: var(--trashie-ui-crisp-white);
}

.GiftCardCover {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    width: 278px;
    overflow: hidden;
    border-radius: 24px;
    margin: 32px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    overflow: visible;

    &.small {
        height: 120px;
        width: 180px;
        border-radius: 8px;
        margin: 0;
        padding: 0;
    }

    &__logo {
        height: 100%;
        max-width: 100%;
        border-radius: 24px;

        &.small {
            border-radius: 8px;
        }
    }

    &__value {
        position: absolute;
        top: -18px;
        right: -30px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        border-radius: 1000px;
        background: var(--take-back-bags-lime);
        z-index: 2;

        color: var(--trashie-ui-crisp-white);
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        text-align: center;
    }
}

.GiftCardCard {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: var(--trashie-ui-faded-black-10);
    padding: 16px;
    gap: 16px;
    background-color: var(--trashie-ui-thrifty-sky);

    &__item {
        display: flex;
        flex-direction: column;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-size: 12px;
            font-weight: 700;
            line-height: 150%;
            margin: 0;
        }

        &--data {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            &-button {
                margin: 0;
                width: -moz-fit-content;
                width: fit-content;
                text-transform: none;
                color: var(--trashie-ui-faded-black-60);
                font-family: Courier;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                background-color: unset;

                p {
                    font-size: 16px;
                }
            }

            &-link {
                color: var(--trashie-primary-code-blue);
                text-decoration-line: underline;
            }
        }
    }
}

.GiftCardStatusPill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 3px;
    padding: 5px 10px;
    border-radius: 20px;
    border: 1px solid var(--trashie-ui-thrifty-sky);
    background: var(--trashie-ui-thrifty-sky);

    img {
        height: 14px;
    }

    span {
        color: var(--trashie-ui-faded-black-100);
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
    }

    &.ERROR {
        background: var(--trashie-ui-error-background);
    }
}

@media screen and (max-width: 1020px) {
    .GiftCardContentMain {
        flex-direction: column;
        align-items: center;

        &__content {
            padding: 16px;

            &-action {
                &-button {
                    display: none;
                }
            }
        }
    }

    .GiftCardCover {
        max-width: 274px;
        max-height: 178px;
        margin: 16px;

        &.small {
            height: 60px;
            width: 96px;
        }
    }
}
