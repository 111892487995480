@import "../../../../scss/trashie";

.StatusTabs {
    margin: 32px 16px 0px 16px;
    display: flex;
    justify-content: center;

    &__button {
        max-width: 160px;
        text-transform: capitalize;
        font-weight: 500;
        color: var(--trashie-ui-faded-black-60);

        &--active {
            background: var(--trashie-ui-faded-black-20);
            color: var(--trashie-ui-faded-black-100);
        }
    }
}

@media screen and (max-width: 768px) {
    .StatusTabs {
        &__section {
            margin-top: 24px;
        }
    }
}