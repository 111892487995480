@import '../../../../scss/_trashie.scss';

.MyRewards {
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 350px;
    background: var(--trashie-ui-faded-black-10);
    scroll-margin-top: 90px;
    flex: 1 1 auto;

    &__list {
        padding-top: 32px;
        flex: 1 1 auto;
    }

    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
        width: 100%;
        max-width: 960px;
        padding: 32px 0px;

        &--container {
            width: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }

        &--loader {
            margin: 80px auto;
        }

        &--section {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;

            &-preview,
            &-preview-highlight {
                padding: 32px;
                border-radius: 20px;
            }

            &-preview-highlight {
                background-color: var(--trashie-primary-thrifty-blue);
            }
        }

        &--tabs {
            width: 100%;
        }
    }

    &__backButton {
        max-width: 72px;
    }
}

@media screen and (max-width: 1020px) {
    .MyRewards {
        &__content {
            padding: 0px 16px 16px;
        }
    }
}

@media screen and (max-width: 768px) {
    .MyRewards {
        &__list {
            padding-top: 24px;
        }

        &__content {

            &--section {
                &-preview {
                    padding: 24px 16px;
                }
            }
        }
    }
}