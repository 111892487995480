@import "../../../../../../scss/trashie.scss";

.RecyclingList {
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: uppercase;
        }

        &--content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--itemsList {
                display: flex;
                flex-direction: column;

                &--item {
                    display: flex;
                    padding: 12px 16px;
                    align-items: center;
                    gap: 12px;
                    align-self: stretch;
                    background: var(--trashie-ui-crisp-white);
                    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

                    span {
                        color: var(--trashie-ui-faded-black-100) !important;
                        font-family: "GT Maru";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                    }
                }
            }

            &--message {
                display: flex;
                padding-top: 32px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-self: stretch;
                color: var(--trashie-ui-faded-black-100, );
                gap: 12px;
                text-align: center;
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                white-space: pre-wrap;
            }
        }

        &--dialog {
            display: flex;
            max-width: 343px;
            width: 100%;
            padding: 32px 24px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 16px;

            &--title {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                text-transform: uppercase;
            }

            &--content {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                &--item {
                    position: relative;
                    padding-left: 10px; 
                    &::before {
                      content: '•'; 
                      position: absolute;
                      left: 0;
                      color: var(--trashie-ui-faded-black-100);
                    }
                  }
            }
        }
    }
}