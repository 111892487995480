@import "../../../../../scss/trashie";

.RewardsResults {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 350px;
  overflow-x: clip;

  &__zero-state-container {
    display: flex;
    width: 100%;
    padding: 0px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    &__title {
      color: var(--trashie-ui-faded-black-100);
      font-size: 18px;
      font-weight: 700;
      font-family: "GT Maru";
      font-style: normal;
      line-height: 150%;
      text-transform: none;
      margin: 0px;
    }

    &__subtitle {
      color: var(--trashie-ui-faded-black-100);
      font-size: 11px;
      font-weight: 400;
      font-family: "GT Maru";
      font-style: normal;
      line-height: 150%;
      text-transform: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: stretch;
    padding: 16px 0 0;

    &--loader {
      margin: 120px auto;
      min-width: 200px;
    }

    &--pane {
      min-height: 400px;
      margin: 32px 0;
      scroll-margin-top: 194px;
      width: stretch;

      &.hasEnvBanner {
        scroll-margin-top: 210px;
      }

      &.isZeroState {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .RewardsResults__content--gift-cards {
        grid-template-columns: repeat(6, 1fr);
      }
    }

    &--scroll {
      // Hide scrollbars
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin: 32px auto;
      width: 100%;
      padding: 16px;

      &-icon {
        height: 32px;
      }

      &-title {
        font-family: "GT Maru";
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        color: var(--trashie-ui-faded-black-100);
        text-align: center;
      }

      &-subtitle {
        font-family: "GT Maru";
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        color: var(--trashie-ui-faded-black-100);
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .RewardsResults {
    &__content {
      &--pane {
        .RewardsResults__content--gift-cards {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .RewardsResults {
    &__content {
      margin: 0 16px 32px;
      padding: 0;

      &--pane {
        margin: 16px 0;
        scroll-margin-top: 206px;

        &.hasEnvBanner {
          scroll-margin-top: 224px;
        }

        .RewardsResults__content--gift-cards {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      &__zero-state-container {
        &__title {
          color: var(--trashie-ui-faded-black-100);
          font-size: 14px;
          font-weight: 700;
          font-family: "GT Maru";
          font-style: normal;
          line-height: 150%;
          text-transform: none;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .RewardsResults {
    &__content {
      &--pane {
        .RewardsResults__content--gift-cards {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}
