@import '../../../../scss/_trashie.scss';

.MyAccount {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 32px 64px 64px 64px;

    &__container {
        width: 100%;
        max-width: 800px;
        height: 100%;
        align-items: center;
        display: flex;
        flex-flow: column;
        gap: 32px;
    }

    &__contentAccount {
        max-width: 960px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__header {
        padding: 8px 0px;
        display: flex;
        gap: 16px;
        align-items: center;
    }

    &__header-container {
        flex: 1 1 0px;
        white-space: nowrap;
        overflow: hidden;
    }

    &__image-container {
        flex: 0 0 64px;
    }

    &__image {
        width: 100%;
    }

    &__button-logout {
        padding: 0px;
    }

    &__userTitle {
        color: var(--ui-faded-black);
        font-size: 28px;
        font-weight: 700;
        line-height: 130%;
        text-transform: capitalize;
    }

    &__infoTitle {
        color: var(--trashie-ui-faded-black-50);
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}


@media screen and (max-width: 768px) {
    .MyAccount {
        padding: 16px;

        &__image-container {
            flex: 0 0 48px;
        }

        &__userTitle {
            font-size: 16px;
            line-height: 120%;
        }

        &__infoTitle {
            font-size: 12px;
            line-height: 120%;
        }

        &__header {
            gap: 8px;
        }
    }
}