@import "../../../../../../scss/trashie";

.DeviceBatteryWarning {
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: uppercase;
        }

        &--content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--text {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                height: 321px;
            }

            &--checkbox {
                .MuiFormControlLabel-label {
                    color: var(--trashie-ui-faded-black-100) !important;
                    font-family: "GT Maru";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }

            }
        }

        &--contact {
            display: flex;
            height: 151px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            color: var(--trashie-ui-faded-black-100);
            text-align: center;
            font-family: "GT Maru";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }
    }
}
