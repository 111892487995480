@import '../../../../../scss/_trashie.scss';

.RewardsWallet {
    &__section {
        padding: 32px 0px;
    }

}

@media screen and (max-width: 768px) {
    .RewardsWallet {
        &__section {
            padding: 24px 16px;

        }
    }
}