@import '../../../../scss/trashie';

.GiftCardInfo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 350px;
    background: var(--trashie-primary-thrifty-blue);

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 800px;
        padding: 64px 16px;

        &--container {
            width: 100%;
        }

        &--loader {
            margin: 80px auto;
        }

        &--button {
            width: fit-content;
        }

        &--section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            background: var(--ui-crisp-white);
            border-radius: 20px;
            overflow: hidden;
            padding: 32px;
            gap: 32px;
        }

        &--cta {
            @include ctaLarge;
            @include ctaPrimary;
            max-width: 320px;
            margin: 16px 0;
        }

        &--message {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;

            &-title {
                color: var(--trashie-ui-faded-black-100);
                font-size: 20px;
                font-weight: 700;
                line-height: 150%;
                text-transform: uppercase;
                margin: 0;
            }

            &-desc {
                color: var(--trashie-ui-faded-black-60);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-transform: none;
                margin: 0;

                a {
                    text-decoration: underline;
                }
            }

            &-info {
                color: var(--trashie-ui-faded-black-60);
                font-size: 12px;
                font-weight: 400;
                line-height: 150%;
                text-transform: none;

                ul {
                    margin-top: 8px;
                }

                a {
                    text-decoration: underline;
                    margin: 0 3px;
                }
            }
        }
    }

    &__ctaContainer {
        display: none;
    }
}

@media screen and (max-width: 1020px) {
    .GiftCardInfo {
        &__content {
            padding: 32px 16px;

            &--section {
                padding: 16px;
            }

            &--cta {
                display: none;
            }
        }
        &__ctaContainer {
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: center;
            padding: 16px 32px; 
            border-top: 1px solid var(--trashie-ui-faded-black-100);
            border-bottom: 1px solid var(--trashie-ui-faded-black-100);
            background: var(--trashie-ui-crisp-white);
            position: sticky;
            bottom: 0;
            width: 100%;
            z-index: 1;

            .cta-shop {
                @include ctaLarge;
                @include ctaPrimary;
                max-width: 326px;
            }
        }
    }
}