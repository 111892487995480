.CollapsableSection {
    width: 100%;

    &__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        border: 0;
        background-color: unset;
    }

    &__title {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;

        &.small {
            font-size: 13px;
        }
    }

    &__icon {
        height: 20px;
        width: 20px;
        margin: 0 8 0 0px;

        &.large {
            height: 26px;
            width: 26px;
        }
    }

    &__content {
        overflow: hidden;
        transition: margin-top 50ms 380ms ease-in-out, max-height 400ms ease-in-out;
        max-height: 0px;

        &--collapsed {
            transition: margin-top 50ms ease-in-out, max-height 400ms 50ms ease-in-out;
        }
    }
}