@import '../../../../scss/_trashie.scss';

.Profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
    padding: 32px 64px 64px 64px;
    text-transform: none;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
        width: 100%;
        max-width: 800px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 16px;
    }

    &__list-headers {
        margin: 0px;
        font-size: 22px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    &__container-header {
        color: var(--trashie-ui-faded-black-100);
        font-size: 16px;
        font-weight: 700;
        line-height: 120%;
    }

    &__pane {
        border-radius: 8px;
        background-color: var(--ui-crisp-white);

        /* Drop Shadow */
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
        padding: 16px;
        width: 100%;
    }

    &__intro {
        color: var(--trashie-ui-faded-black-100);
        font-family: "GT Maru";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-top: 16px;
        margin-bottom: 0px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 16px;

    }

    &__form-item {
        padding: 0;

        p {
            color: var(--trashie-ui-faded-black-100);
            font-size: 16px;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 8px;
            text-transform: none;
        }
    }

    &__loader {
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    &__password {
        padding: 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 120%;
    }

    &__password-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }

    &__edit-icon {
        width: 24px;
    }

    &__password-text {
        transform: translateY(2px);
    }

}

@media screen and (max-width: 768px) {
    .Profile {
        padding: 24px 16px 16px 16px;


        &__loader {
            margin: 16px;
        }
    }
}