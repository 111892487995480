.InfoSection {
    width: 100%;
    &__title {
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
    }

    &__description {
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 8px;
    }
}
