.main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
    border-bottom: 2px solid var(--ui-wow-light-gray);
    background: var(--trashie-ui-crisp-white);

    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 1280px;
        padding: 8px 0px;

        .rowContent {
            display: flex;
            align-items: center;
        }

        .origin {
            padding: 14px 16px;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid var(--ui-faded-black);
            width: 230px;
            height: 26px;
            display: flex;
            align-self: center;

            .originText {
                color: var(--ui-faded-black);
                text-align: right;
                font-family: GT Maru;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 118.6%;
                text-transform: uppercase;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .details {
            display: flex;
            flex: 1;
            flex-flow: row;
            margin-left: 16px;
            align-items: center;
            min-width: 160px;
            gap: 12px;

            .detailsContainer{
                display: flex;
                flex-direction: column;
            }

            .detailsTitle {
                display: flex;
                flex-direction: row;
                gap: 4px;
            }

            .merchant {
                color: var(--ui-faded-black);
                font-family: GT Maru;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-transform: uppercase;
                margin-bottom: 8px;
                text-overflow: ellipsis;
                text-transform: uppercase;
                overflow: hidden;
                white-space: nowrap;
            }

            .sale {
                color: var(--trashie-ui-faded-black-100);
                font-family: GT Maru;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-transform: uppercase;
                margin-right: 8px;
            }

            .date {
                color: var(--ui-faded-black-60);
                font-family: GT Maru;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-transform: capitalize;
            }
        }

        .amount {
            color: var(--trashie-ui-faded-black-100);
            font-family: GT Maru Mono;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: uppercase;

            .negative {
                color: var(--japan-color-dry-red);
            }
            .amountContainer{
                display: flex;
                align-items: center;
                gap: 4px;
            }
            .mark {
                height: auto;

                .tbbMark {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 16px;
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                    background: var(--japan-color-hard-mint);

                    img {
                        height: 27px;
                    }
                }
            }
        }

        .status {
            text-align: left;
            border-radius: 100px;
            background: var(--trashie-ui-thrifty-sky);
            display: flex;
            height: 18px;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .statusText {
                color: var(--trashie-primary-code-blue);
                text-align: center;
                font-family: "GT Maru";
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 90%;
                text-transform: uppercase;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }


}

.tcIcon {
    height: 16px;
    width: 16px;
}

@media screen and (max-width: 1020px) {
    .main {

        .container {
            flex-direction: row;
            flex-wrap: wrap;

            .origin {
                width: 180px;
                min-width: 180px;
                margin-bottom: 10px;
                height: 26px;
                align-self: flex-start;
            }

            .details {
                margin: 0;

                .merchant {
                    max-width: 180px;
                    margin: 0;
                }
            }

            .amount {
                flex-flow: column;
                align-self: flex-end;
                align-items: flex-end;
                font-size: 20px;

                .mark {
                    height: 33px;

                    .tbbMark {
                        width: 33px;
                        height: 33px;
                        margin: 0;
                    }
                }
            }

            .status {
                align-self: flex-end;
                width: auto;

                .statusText {
                    margin: 0;
                }
            }
        }
    }
}
