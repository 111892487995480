.MessageContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 120px 32px;
    gap: 16px;

    &.embedContent {
        margin: 20px 32px  !important;
    }

    &__image {
        height: 50px;
    }

    &__text {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 8px;
        color: var(--trashie-ui-faded-black-100);
        text-align: center;

        &-title {
            margin: 0;
            font-size: 20px;
            font-weight: 700;
            line-height: 140%;
        }

        &-subtitle,
        &-message {
            margin: 0 0 24px;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            text-transform: none;
            max-width: 320px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__loader {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 16px;
    }
}
