.PageContent {
    display: flex;
    justify-content: center;
    width: 100%;
    scroll-margin-top: 90px;
    padding: 0 48px;

    &__container {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        width: 100%;
        gap: 24px;
        max-width: 1344px; // width + padding
        padding: 32px 0 64px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        gap: 16px;
        margin: 0 16px;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-size: 28px;
            font-weight: 700;
            line-height: 100%;
            text-transform: none;
            margin: 0;
        }

        &--subtitle {
            color: var(--trashie-ui-faded-black-70);
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
            text-transform: none;
            margin: 0;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;
        min-height: 400px;

        &--loader {
            margin: 120px auto;
            width: 100%;
        }

        &--error {
            color: var(--trashie-ui-faded-black-100);
            font-size: 20px;
            font-weight: 500;
            line-height: 100%;
        }
    }

    &__cta {
        display: flex;
        flex: 1;
        justify-content: center;
        margin: 9px 16px 0;
    }
}

@media screen and (max-width: 1020px) {
    .PageContent {
        padding: 0 8px;

        &__container {
            gap: 16px;
            padding: 24px 0;
        }

        &__header {
            &--title {
                font-size: 18px;
            }

            &-subtitle {
                font-size: 14px;
            }
        }
    }
}