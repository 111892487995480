@import '../../../../scss/_trashie.scss';

.Settings {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
    padding: 32px 64px 64px 64px;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 800px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 16px;
    }

    &__container {
        border-radius: 8px;
        background-color: var(--ui-crisp-white);

        /* Drop Shadow */
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
        padding: 16px;
        width: 100%;
    }



    &__intro {
        color: var(--trashie-ui-faded-black-100);
        font-family: "GT Maru";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-top: 16px;
        margin-bottom: 0px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 16px;

    }

    &__form-item {
        padding: 0;

        p {
            color: var(--trashie-ui-faded-black-100);
            font-size: 16px;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 8px;
        }
    }

    &__cta {
        max-width: max-content;
        margin-top: 24px;
    }

    &__options {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
    }

    &__option {
        color: var(--ui-faded-black);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background-color: unset;
        text-transform: uppercase;
        text-decoration-line: underline;
        border: 0;
    }

    &__loader {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    .Settings {
        padding: 24px 16px 16px 16px;


        &__loader {
            margin: 16px;
        }
    }
}