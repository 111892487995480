.RewardsSearch {
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: stretch;
    row-gap: 1rem;

    &-header {
      display: flex;
      justify-content: space-between;
      column-gap: 0.5rem;
      padding: 0;
      width: 100%;
      max-width: 420px;
      align-items: center;

      &-back {
        padding: 0;
        border: none;
        background: none;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .RewardsSearch__content-input {
      display: flex;
      flex-direction: row;
      width: calc(100% - (2 * 24px));
      height: 48px;
      padding: 8px 16px;
      justify-content: space-between;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid var(--trashie-ui-faded-black-60);
      background: var(--trashie-ui-crisp-white);
      z-index: 99;
      column-gap: 0.5rem;
    }

    &-results {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 80vw;
      padding: 0px 0 0;
    }
  }

  &__input {
    color: var(--trashie-ui-faded-black-60) !important;
    font-family: "GT Maru" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    width: 100%;
  }

  &__close-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 768px) {
  .RewardsSearch {
    &__content {
      padding: 0px 10px;
      &-input {
        top: 80px;
      }
    }
  }
}
