.DashboardDeals {
  &__slide {
    display: flex;
    max-height: 320px;
    max-width: 1344px;
    width: 100%;
    margin: 32px 0 16px;
    padding: 0 16px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 16px;
    }
  }
}

@media screen and (max-width: 1020px) {
  .DashboardDeals {
    &__slide {
      max-height: 800px;
      margin: 16px 0;
    }
  }
}
