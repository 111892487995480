@import "../../../../../scss/trashie";

.GenericBanner {
    display: flex;
    justify-content: space-between;
    padding: 0px 64px;
    width: 100%;
    border-radius: 8px;
    background: var(--trashie-ui-thrifty-sky);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    overflow: hidden;

    &__container {
        display: flex;
        width: 426px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        gap: 16px;
        padding: 64px 0px;
        text-align: left;
        text-transform: none;

        font-family: "GT Maru";
        line-height: 150%;
    }

    &__title {
        font-size: 22px;
        font-weight: 700;
    }

    &__description {
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        color: var(--trashie-ui-faded-black-80);
        white-space: pre-line;
    }

    &__cta {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--trashie-primary-code-blue);

        text-decoration: none;

        &:hover {
            color: var(--trashie-primary-code-blue);
            text-decoration: underline;
        }
    }

    &__img {
        display: flex;
        align-items: center;
        margin: 20px;
        padding-right: 22px;
        position: relative;
        flex: 1 1 auto;
        justify-content: flex-end;
    }

    &__icon {
        z-index: 2;

        width: 230px;
        filter: drop-shadow(10px 25px 10px hsla(0, 0%, 0%, 0.2));
    }

    &__star {
        width: 800px;
        height: 800px;
        position: absolute;
        top: -300px;
        left: -200px;
        z-index: 1;
    }

}

@media screen and (max-width: 1020px) {
    .GenericBanner {
        &__icon {
            margin: 20px 0px;
            width: 200px;
        }

        &__img {
            width: 200px;
        }

        &__star {
            width: 600px;
            height: 600px;
            top: -180px;
            left: -150px;
        }
    }
}

@media screen and (max-width: 768px) {
    .GenericBanner {
        padding: 0px 16px;
        gap: 8px;

        &__container {
            padding: 16px 0px;
            gap: 8px;
        }

        &__title {
            font-size: 18px;
        }

        &__description {
            font-size: 11px;
            line-height: 120%;
        }

        &__icon {
            width: 116px;
            min-width: 116px
        }

        &__img {
            margin: 20px 0px;
            padding-right: 0px;
            min-width: 116px
        }

        &__cta {
            font-size: 14px;
        }

        &__star {
            width: 444px;
            height: 444px;
            top: -167px;
            left: -111px;
        }
    }
}