@import '../../../../scss/_trashie.scss';

.TakeBackBags {
    display: flex;
    width: 100%;
    padding: 64px;
    background: var(--trashie-ui-faded-black-10);

    &__items-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 100%;
        text-transform: none;
    }

    &__container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;
        gap: 32px;
        max-width: 960px;
    }

    &__slider {
        width: 100%;
    }

    &__items {
        width: 100%;
    }
}

@media screen and (max-width: 1020px) {

    .TakeBackBags {
        padding: 32px 16px;

        &__slider {
            width: 100dvw;
            overflow-x: clip;
            padding: 0px 16px;
        }
    }
}

#nonTbbUserContent {
    background: var(--gradient-light-dream-pop-pink);

    .GenericBanner__icon {
        width: 387px;
        position: absolute;
        right: -100px;
        bottom: -30px;
    }
}

@media screen and (max-width: 1020px) {
    #nonTbbUserContent {
        .GenericBanner__icon {

            right: -80px;
            bottom: -50px;
        }

        .GenericBanner__description {
            text-align: start;
        }
    }
}

@media screen and (max-width: 768px) {
    #nonTbbUserContent {
        min-height: 184px;

        .GenericBanner__icon {
            width: 258px;
            right: -80px;
            bottom: -35px;
        }

        .GenericBanner__title {
            font-size: 20px;
            line-height: 120%;
        }

        .GenericBanner__img {
            min-width: 175px;
        }
    }
}

#tbbSlider {
    .splide__track {
        padding: 0px !important;
        overflow: unset;
    }

    .splide__list {
        justify-content: space-between;
        gap: 16px;
    }

    .splide__slide {
        margin: 0px !important;
    }
}