.DashboardRewards {
    display: flex;
    width: 100%;
    padding: 0 48px;
    flex-direction: column;
    overflow: hidden;

    &__container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;

        &--slider {
            display: flex;
            max-height: 320px;
            max-width: 1344px;
            width: 100%;
            margin: 32px 0 16px;
            overflow: hidden;

            .splide {
                overflow: hidden;
                border-radius: 8px;
            }

            &-slide {
                display: flex;
                width: 100%;
                height: 100%;
                max-height: 100%;
                border-radius: 8px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .DashboardRewards {
        padding: 0;
        background: none;

        &__container {
            &--slider {
                max-height: 800px;
                margin: 16px 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .DashboardNew {
        &__container {
            &--slider {
                max-height: 420px;
            }
        }
    }
}
