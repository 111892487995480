.titleContainer{ 
    max-width: 960px;
    width: 100%;
}

.buttonContainer{
    max-width: 315px;
    gap: 10px;
}

@media screen and (max-width: 1020px) {
        .titleContainer{
            width: 100%;
        }
        .buttonContainer{
            width: 72px;
        }
        .buttonText{
            display: none;
        }
}
