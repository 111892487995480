@import "../../../../scss/trashie";

.SearchBar {
  max-width: 360px;
  width: 100%;

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    font-family: Gt Maru, sans-serif;

    @media (max-width: 1080px) {
      padding: 24px 16px;
    }

    @media (max-width: 600px) {
      .RewardsViewList {
        padding: 0;
      }

      .RewardsViewItem__caret--content-text {
        padding: 0 2px;
      }
    }
  }
}
