@import '../../../../scss/_trashie.scss';

.Slider {
    visibility: visible;

    .splide__track {
        padding: 16px !important;
    }

    .splide__pagination__page {
        background: #8D8D8D;

        &.is-active {
            background: #1b1a1b;
            transform: scale(1);
        }
    }

    &.overflow {
        .splide__track {
            overflow: visible;
        }

        .splide__arrows {
            .splide__arrow {
                background: var(--trashie-ui-faded-black-10);
                width: 32px;
                height: 32px;
                opacity: 1;
                top: 50%;
                filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.2));
                transition: opacity .5s ease, display .5s ease allow-discrete;

                svg {
                    display: none;
                }
    
                &.splide__arrow--prev,
                &.splide__arrow--next {
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: 28px;
                }
    
                &.splide__arrow--prev {
                    background-image: url("../../images/chevron-left.svg");
                    left: 0px;
                }

                &.splide__arrow--next {
                    background-image: url("../../images/chevron-right.svg");
                    right: 0px;
                }

                &:disabled {
                    display: none;
                    opacity: 0;
                }
            }
        }
    }

    &.arrows-top {
        .splide__arrows {
            .splide__arrow {
                top: 34%;
            }
        }
    }

    &.hasDots {
        padding-bottom: 36px;
    }

    &.mobileXXL {
        .splide__track {
            overflow: hidden;
        }
    }
}
