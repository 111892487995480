.DashboardSection {
    display: flex;
    justify-content: center;
    width: 100%;
    scroll-margin-top: 90px;

    &__container {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        width: 100%;
        gap: 12px;
        max-width: 1344px; // width + padding
        padding: 32px 0 16px;
        min-height: 200px;

        &.contained {
            padding: 0;
        }

        &--top {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0 16px;

            &.contained {
                margin: 0;
            }

            &-text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                &-title {
                    color: var(--trashie-ui-faded-black-100);
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 100%;
                    text-transform: none;
                    margin: 0;

                    img {
                        margin-right: 6px;
                        vertical-align: sub;
                        height: 24px;
                    }
                }

                &-subTitle {
                    color: var(--trashie-ui-faded-black-70);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                    text-transform: none;
                    margin: 0;
                }
            }

            &-cta {
                padding: 0 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: fit-content;
                min-width: fit-content;

                svg {
                    rotate: 90deg;
                }
            }
        }

        &--content {
            display: flex;
            width: 100%;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-flow: column;

            font-size: 20px;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
            text-align: center;
        }

        &--loader {
            margin: 80px auto;
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .DashboardSection {
        &__container {
            gap: 16px;
            padding: 16px 0;

            &--top {
                &-text {
                    &-title {
                        font-size: 18px;
                        line-height: 150%;
                    }

                    &-subTitle {
                        font-size: 12px;
                    }
                }

                &-cta {
                    max-width: 100%;
                    padding: 4px 16px 4px;
                    font-size: 12px;
                }
            }
        }
    }
}
