@import '../../../../scss/_trashie.scss';

.Wallet {
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 350px;
    background: var(--trashie-ui-faded-black-10);
    scroll-margin-top: 138px;
    flex: 1 1 auto;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
        width: 100%;
        max-width: 960px;
        padding: 0px 16px;
        flex: 1 1 auto;

        &--container {
            width: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }

        &--loader {
            margin: 80px auto;
        }

        &--section {
            margin-bottom: 32px;
            flex: 1 1 auto;
        }

        &--tabs {
            width: 100%;
            position: sticky;
            top: 90px;
            z-index: 9;
            scroll-margin-top: 90px;
            background-color: var(--trashie-ui-faded-black-10);

            &.hasEnvBanner {
                top: 108px;
                scroll-margin-top: 108px;
            }
        }
    }

    &__backButton {
        max-width: 72px;
    }
}

@media screen and (max-width: 1020px) {
    .Wallet {
        &__content {
            &--tabs {
                top: 48px;
                scroll-margin-top: 48px;

                &.hasEnvBanner {
                    top: 66px;
                    scroll-margin-top: 66px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .Wallet {
        &__content {
            padding: 0;

            &--section {
                &-preview {
                    padding: 24px 16px;
                }
            }
        }
    }
}