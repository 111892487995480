@import '../../../../scss/_trashie.scss';

.TitleSectionAccount {
    margin: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__titleStyle {
        color: var(--ui-faded-black);
        font-family: GT Maru;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        align-self: stretch;
    }

    &__subTitleSection {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 5px
    }

    &__subTitleStyle {
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    &__urlTitleStyle {
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-decoration-line: underline;
        text-transform: uppercase;
    }

}

.TitleSectionAccount_titleTransForm .TitleSectionAccount__titleStyle {
    line-height: 100%;
    text-transform: none;
}

@media screen and (max-width: 1020px) {
    .TitleSectionAccount {
        margin: 8px 0px;

        &__titleStyle {
            font-size: 18px;
        }

        &__subTitleStyle {
            font-size: 14px;
        }
    }
}