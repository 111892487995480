.RewardsSearchInput {
    &__input-container {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 8px 24px;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;

        border-radius: 100px;
        border: 1px solid var(--trashie-ui-faded-black-30);
        background: var(--trashie-ui-crisp-white);
    }

    &__input {
        width: 90%;
        ::placeholder {
            color: var(--trashie-ui-faded-black-60);
            font-family: "GT Maru";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }
    }

    &__search-icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
    }
}