@import "../../../../../../scss/trashie";

.EmailForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__emailContainer{
        margin-top: 24px;
        p {
            background: transparent !important;
        }

        &--inputEmail{
            background:  var(--trashie-ui-faded-black-10);
            font-family: GT Maru;
            font-size: 18px; 
            padding: 16px 0px;
        }
    }

    
    &__buttonContainer{
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
