.RewardFlag {
    position: absolute;
    top: 6px;
    right: 6px;
    margin: 0;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 140px;
    padding: 4px 8px;
    border: 0;
    background: var(--ui-crisp-white);

    color: var(--trashie-ui-faded-black-100);
    font-size: 10px;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    z-index: 1;

    gap: 2px;

    p {
        margin: 0;
    }

    &__icon {
        width: 12px;
        height: 12px;
    }
}