.DealsPreviewBanner {
  display: flex;
  justify-content: center;
  width: 100%;
  scroll-margin-top: 90px;
  border-radius: 16px;
  background-color: var(--trashie-primary-thrifty-blue);
  overflow: hidden;

  &__container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    max-width: 1344px;
    min-height: 200px;
    width: 100%;

    &--panel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      padding: 32px 112px 32px 0;
      background-position: bottom;  
      background-repeat: no-repeat;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      background-color: var(--trashie-primary-thrifty-blue);
      z-index: 2;
      width: 50%;

      &-message {
        font-size: 32px;
        font-weight: 900;
        line-height: 130%;
        text-align: left;
        margin: 16px 0  0 16px;
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 32px 0;
      width: 50%;
    }

    &--buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      padding: 0 16px;
    }

    &--cta {
      width: fit-content;
      margin: 0 0 16px 16px;
    }
  }
}

@media screen and (max-width: 992px) {
  .DealsPreviewBanner {
    border-radius: 0;

    &__container {
      flex-direction: column;
      gap: 0;
      padding: 16px 0;
      
      &--panel {
        padding: 16px 0;
        background-position: top right;
        background-size: 120px;
        width: 100%;

        &-message {
          max-width: 50%;
          font-size: 28px;
          line-height: 100%;
        }
      }

      &--content {
        padding: 0;
        width: 100%;
      }

      &--cta {
        align-self: center;
        width: 100%;
        max-width: 400px;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .DealsPreviewBanner {
    &__container {
      &--panel {
        background-size: 150px;
      }
    }
  }
}
