@import '../../../../../scss/_trashie.scss';

.ZeroState {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 64px;
    gap: 34px;
    border-radius: 20px;
    background: var(--trashie-ui-crisp-white);
    width: 100%;
    max-width: 960px;

    &__image {
        height: 48px;
    }

    &__text {
        display: flex;
        flex-flow: column;
        gap: 16px;
        align-items: center;
    }


    &__title {
        font-family: 'GT Maru';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
        color: var(--trashie-ui-faded-black-100);
        text-align: center;
    }

    &__message {
        font-family: 'GT Maru';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        color: var(--trashie-ui-faded-black-100);
        text-align: center;
    }

    &__cta {
        @include ctaPrimary;
        @include ctaLarge;

        width: fit-content;
    }
}

@media screen and (max-width: 768px) {
    .ZeroState {
        height: 100%;
        justify-content: center;
        padding: 16px;
        gap: 16px;

        &__title {
            font-size: 20px;
        }

        &__text {
            width: 100%;
        }

        &__cta {
            width: 100%;
        }
    }
}