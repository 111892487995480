.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;
    & > :first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    & > :last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
}
