.MyGiftCardsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 16px;

  &__giftCardRow {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}
