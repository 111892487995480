.NavigationBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  gap: 8px;
  width: 100%;

  &__title {
    display: flex;
    flex: 1;
    justify-content: center;
    color: var(--trashie-ui-faded-black-100);
    text-align: center;
    font-family: "GT Maru";
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    text-transform: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    img {
      margin-right: 6px;
      vertical-align: sub;
      height: 20px;
    }
  }

  &__back,
  &__forward {
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: unset;
    cursor: pointer;

    &:hover {
      color: var(--trashie-ui-faded-black-50);
    }

    &:disabled {
      cursor: default;
    }
  }

  &__forward {
    transform: rotate(180deg);
  }
}
