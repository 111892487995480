.Sorting {
    max-width: 166px;
    width: 100%;

    &__button {
        height: 48px;
        padding: 0px 24px;
        font-size: 12px;
        border-radius: 100px;
        border: 1px solid var(--trashie-ui-faded-black-30);
        background: var(--trashie-ui-crisp-white);
        gap: 4px;
        text-transform: none;

        &--label{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

            div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    &__modal {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 24px 8px 48px 8px;
        width: 375px;
    
        &--title {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            padding: 0px 16px 12px 16px;
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            align-items: center;
            border-bottom: 1px solid var(--trashie-ui-faded-black-30); 
    
            &--close {
                cursor: pointer;
            }
        }
    
        &--options {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 0px 16px 12px 16px;
    
            &--option {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                color: var(--trashie-ui-faded-black-60);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 175%;
                cursor: pointer;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .Sorting {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .Sorting {
        &__button {
            padding: 8px 16px;
            font-size: 12px;
            border-radius: 100px;
            gap: 4px;
        }
        &__modal {
            width: 100%;
        }
    }
}