@import '../../../../scss/_trashie.scss';

.MyRewardItemModal {

    &__body {
        display: flex;
        flex-direction: column;
        margin-top: 48px;
        margin-bottom: 16px;
        padding: 16px 32px;
        width: 100%;
        border-radius: 12px;
    }

    &__card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        margin: 32px 0px;
        padding: 0px;
    }

    &__main-text {
        color: var(--trashie-ui-faded-black-100);
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__secondary-text {
        color: var(--trashie-ui-faded-black-60);
        font-size: 16px;
        font-weight: 500;
    }

    &__buttons {
        display: flex;
        gap: 8px;
    }

    &__support {
        color: var(--trashie-primary-code-blue);
    }
}