@import '../../../../scss/trashie.scss';

.RegisterView {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  .RegisterMaxWidth {
    max-width: 434px;
    container-type: inline-size;
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 100%;
    max-width: inherit;
    background: var(--trashie-ui-faded-black-10);
  
    &.rainbowBg {
      background: var(--trashie-ui-faded-black-10) url('../../images/rainbow-bg.png') no-repeat left top;
    }
  }

  &__header {
    background: var(--ui-crisp-white);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.09));
  
    &--content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;
      @extend .RegisterMaxWidth;
  
      img {
        max-width: 90px;
      }
    }
  }

  &__progressContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  
    @extend .RegisterMaxWidth;
  }

  &__code {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 16px;

    @extend .RegisterMaxWidth;

    &--text {
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      color: var(--trashie-ui-faded-black-70);
    }
  }

  &__contentContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    padding: 32px 16px 0;
  
    @extend .RegisterMaxWidth;

    .nav-tabs {
      display: none;
    }

    .tab-content {
      display: flex;
      flex: 1;
      justify-content: center;
      width: 100%;

      .tab-pane {
        display: flex;
        flex: 1;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
      }
    }

    .loader {
      display: flex;
      width: 100%;
      margin: auto 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    justify-content: center;
    align-items: center;
    padding: 24px 24px 32px;
    width: 100%;

    @extend .RegisterMaxWidth;
  
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    color: var(--trashie-ui-faded-black-50);
    margin-top: auto;
    gap: 3px;
  
    a {
      color: var(--trashie-ui-faded-black-50);
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width:480px) {
  .RegisterView {
    &__container {
      padding: 0 !important;

      .row {
        padding-left: 0;
        padding-right: 0 !important;
      }
    }

    &__header {
      width: 100%;

      &--content {
        padding: 10px 16px;
      }
    }

    &__contentContainer {
      padding: 16px 16px 0;
    }
  }
}
