@import '../../../../../scss/_trashie.scss';

.DealsWallet {
    &__section {
        padding: 32px 0px;
        height: 94%;
    }

    &__loader {
        margin: 80px auto;
    }
}

@media screen and (max-width: 768px) {
    .DealsWallet {
        &__section {
            padding: 24px 16px;

        }
    }
}