.DisclaimerPublic {
  color: var(--trashie-ui-faded-black-50);
  text-align: center;
  font-family: GT Maru;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  margin: 16px 32px;
  text-transform: none;

  &__link {
    margin: 0 3px;
    text-decoration-line: underline;
    color: var(--trashie-ui-faded-black-50);

    &:hover {
      color: var(--trashie-ui-faded-black-50);
    }
  }
}
