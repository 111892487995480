.ShippingProviderSelectItem {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    border-radius: 8px;
    background: var(--trashie-ui-crisp-white);
    border: 1px solid var(--trashie-ui-faded-black-60);
    width: 100%;

    &.selectedCardContainer {
        border-radius: 8px;
        border: 1px solid var(--trashie-ui-faded-black-80);
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

        &:hover {
            border: 1px solid var(--trashie-ui-faded-black-70) !important;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        align-self: stretch;
        gap: 12px;

        .shippingProviderImage {
            height: 24px;
            flex-shrink: 0;
        }

        .shippingProviderDescriptionContainer {
            display: flex;
            flex-direction: column;
            width: 100%;

            .shipingProviderNameContainer {
                display: flex;
                flex-direction: row;
                gap: 8px;
                justify-items: center;

                .shipingProviderNameText {
                    color: var(--trashie-ui-faded-black-100);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 150%;
                    text-transform: uppercase;
                    margin: 0;
                }

                .radioButton {
                    margin-left: auto;
                    appearance: none;
                    height: 18px;
                    width: 18px;
                    min-width: 18px;
                    border: 1px solid var(--trashie-ui-faded-black-100);
                    border-radius: 50%;
                    background-color: transparent;
                    outline: none;
                    cursor: pointer;

                    &:not(:disabled):checked {
                        background-clip: content-box;
                        background-color: var(--trashie-primary-code-blue);
                        padding: 2px;
                        min-width: 18px;
                    }

                    &:disabled {
                        border: 1px solid var(--trashie-ui-faded-black-60);
                    }
                }
            }

            .shipingProviderPromotionText {
                color: var(--take-back-bags-lime);
                font-size: 12px;
                font-weight: 400;
                line-height: 150%;
                margin: 0;
            }
        }
    }

    &__details {
        color: var(--trashie-ui-faded-black-100);
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        text-align: justify;
    }

    &__storesLink {
        color: #1A1B1A;
        font-family: GT Maru;
        font-size: 13px;
        font-style: normal;
        line-height: 150%;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.shipingProviderPromotionnNote {
    color: var(--trashie-ui-faded-black-80);
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
}
