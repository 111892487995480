.DynamicDealsBanner {
    width: 100%;
    height: 100dvh;
    overflow: hidden;
    position: relative;

    &__loading {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 16px;
        grid-column-gap: 16px;
        width: 100%;
        padding: 10px;
        overflow: hidden;
        position: relative;

        will-change: transform;

        &>*:nth-child(4n + 2) {
            bottom: 20%;
        }

        &>*:nth-child(4n + 3) {
            top: 20%;
        }

        animation: scrollVertical 20s linear infinite both;
    }

}

@keyframes scrollVertical {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-1000px);
    }
}

@media screen and (max-width: 1200px) {
    .DynamicDealsBanner {

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 50%;
            background: url('../../images/login-gradient.png');
            pointer-events: none;
        }

        &__container {
            column-gap: 8px;
        }
    }
}
