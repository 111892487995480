.ButtonBack {
    width: fit-content;
    color: var(--trashie-ui-faded-black-60);
    font-family: "GT Maru";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
    display: flex;
    gap: 4px;
    align-items: center;
    align-content: center;
    border: 0;
    background-color: unset;
    padding: 0;

    &:hover {
        color: var(--trashie-ui-faded-black-60);
    }

    &__label {
        display: block;
        margin-bottom: -2px;
    }
}