.QrCodeForm {
    &__modalContent {
        display: flex;
        padding: 32px 16px;
        flex-direction: column;
        gap: 32px;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
        }

        &--qrcode {
            display: flex;
            padding: 16px 4px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 16px;
            background: var(--trashie-ui-faded-black-20);
            color: var(--trashie-ui-faded-black-100);
            text-align: center;
            font-family: "GT Maru";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }

        &--ctas {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    &__inputContainer{
        margin-top: 24px;
        p {
            background: transparent !important;
        }

        &--input{
            background:  var(--trashie-ui-faded-black-10);
            font-family: GT Maru;
            font-size: 18px; 
            padding: 16px 0px;
        }
    }

    &__option{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 32px;

        &--text{
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            margin: 0;
        }
    }
}