.StickersAdvice {
    &__container {
        width: 100%;

        &--video {
            width: 100%;
            align-self: stretch;
        }

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: uppercase;
        }

        &--content {
            margin-top: 8px;

            &--text {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }

            &--actions {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-top: 16px;
            }

            &--advice {
                display: flex;
                padding-top: 32px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-self: stretch;
                gap: 12px;

                &--link {
                    color: var(--trashie-primary-code-blue);
                    font-family: "GT Maru";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    text-transform: uppercase;
                }
            }
        }
    }
}
