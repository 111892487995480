.GiftCardsList {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 8px 16px 16px;

  // Hide scrollbars
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .GiftCardsList {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .GiftCardsList {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .GiftCardsList {
    gap: 24px 8px;
    grid-template-columns: repeat(2, 1fr);
  }
}
