.SliderSelector {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin: 16px 0;
    color: var(--trashie-ui-faded-black-100);

    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    &__message {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        margin-bottom: 16px;
        color: var(--trashie-ui-faded-black-60);
    }

    &__slider {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border: 1px solid var(--trashie-ui-faded-black-30);
        border-radius: 10px 10px 0 0;
        padding: 16px;

        &--exceed {
            color: var(--take-back-bags-marmalade-11);
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--trashie-ui-faded-black-30);
        border-top: 0;
        border-radius: 0 0 10px 10px;
        padding: 16px 16px 4px;

        &--item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            &-label {
                color: var(--trashie-ui-faded-black-100);
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
            }

            &-data {
                display: flex;
                align-items: center;
                gap: 4px;
                color: var(--trashie-ui-faded-black-100);
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;

                span { 
                    padding: 12px 0;
                }
            }
        }
    }
}
